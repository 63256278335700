
@use 'sass:math';
@import '../../scss/config/config';
@import 'sass-mq/mq';

.register-address-form {
  margin: 0 0 $grid-gutter-width;
}

.divider {
  background-color: $gray-900;
  opacity: 1;
}

.register-address-products {
  display: grid;
  grid-gap: math.div($grid-gutter-width, 2);
  grid-template-columns: 1fr;

  @include mq($from: tablet) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.form-label {
  color: $dark-blue;
  font-size: 16px;
  font-weight: 600;
}
