.homepage-app-wrapper {
  padding: 0 .25rem;

  @include mq($from: tablet) {
    padding: 0;
  }
}

.homepage-app-title {
  color: $dark-blue;
  font-size: 20px;
  font-weight: 700;
  text-align: center;

  @include mq($from: mobile) {
    font-size: 28px;
    text-align: left;
  }

  @include mq($from: tablet) {
    font-size: 38px;
  }
}

.homepage-app-subtitle {
  color: #525f7f;
}

.homepage-app {
  display: flex;
  flex-direction: column;
  gap: $grid-gutter-width;
  grid-template-columns: 1fr;
  margin: 2rem 0;

  @include mq($from: tablet) {
    align-items: center;
    display: grid;
    flex-direction: inherit;
    grid-template-columns: repeat(2, 50%);
    margin: 2rem 0;
  }

  &.homepage-app-mockup {
    flex-direction: column-reverse;
    margin: 3rem 0;

    @include mq($from: tablet) {
      margin: 4rem 0 0;
    }
  }

  .homepage-button-wrapper {
    margin-top: 2rem;

    @include mq($from: tablet) {
      margin-top: 0;
    }
  }
}

.homepage-app-sticker {
  margin-top: 2rem;

  @include mq($from: tablet) {
    margin-top: 1rem;
  }

  @include mq($from: extra-wide) {
    left: 50%;
    margin-top: 0;
    position: absolute;
    top: 0;
  }
}
