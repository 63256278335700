
@import '../../scss/config/config';
@import 'sass-mq/mq';

.order-summary {
  margin-block: 32px;

  @include mq($from: desktop) {
    background-color: $pink-200;
    margin-block: 0;
    padding-left: 30px;
  }

  @include mq($from: wide) {
    justify-self: end;
    max-width: 464px;
    padding-left: 64px;
  }
}

.order-summary-title {
  display: none;
  font-size: 32px;
  text-align: center;

  @include mq($from: desktop) {
    display: block;
    margin-block: 64px 41px;
  }
}

.order-summary-content {
  @include mq($from: desktop) {
    background-color: $white;
    border: 1px solid $purple-200;
    border-radius: 8px;
    padding: 20px;
  }
}

.order-rows {
  background-color: $white;
  border: 1px solid $purple-200;
  border-radius: 8px;
  padding: 20px;

  @include mq($from: desktop) {
    all: unset;
  }
}

.order-row {
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: 500;
  gap: 16px;
  justify-content: space-between;
  line-height: 1em;

  &.tax {
    font-weight: 400;
    margin-top: 8px;
  }

  &.bordered {
    border-top: 1px solid $purple-200;
    margin-top: 16px;
    padding-top: 16px;
  }
}

.order-value {
  text-align: center;

  .kit-info {
    font-size: 16px;
    font-weight: 400;
  }
}

.form-check {
  line-height: 1.2em;
  margin-block: 32px;
  padding-left: 40px;

  .form-check-input {
    margin-left: -40px;
  }

  a {
    color: $body-color;
    font-weight: 700;
    text-decoration: none;
  }
}

.extra-infos {
  display: none;

  @include mq($from: desktop) {
    display: block;
    margin-top: 56px;
  }

  .h4 {
    color: $pink-700;
    font-size: 32px;
    line-height: 1em;
    text-align: center;
  }

  .underline {
    background-color: $primary;
    display: block;
    height: 1px;
    margin: 28px auto;
    width: 255px;
  }
}

.extra-infos-list {
  list-style-type: none;
  margin: 0;
  padding-left: 50px;

  li {
    align-items: center;
    display: grid;
    font-size: 14px;
    gap: 11px;
    grid-template-columns: 13px minmax(0, 1fr);
    line-height: 20px;

    + li {
      margin-top: 10px;
    }

    svg {
      width: 13px;
    }
  }
}
