
@import '../../scss/config/config';
@import 'sass-mq/mq';

.breadcrumb {
  align-items: center;
  gap: 10px;
  width: 100%;

  .progress {
    flex-grow: 1;
  }

  .current-step {
    color: $steel;
    font-size: 14px;
    font-weight: 700;
  }
}
