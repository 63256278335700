
@import '../../scss/config/config';
@import 'sass-mq/mq';

.application-copy {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  position: inherit;

  @include mq($from: desktop) {
    position: absolute;
    right: 30px;
    top: 30px;
  }
}

.application-copy-btn {
  align-items: center;
  background-color: $medium-blue;
  border: 0;
  border-radius: 14px 0 0 14px;
  color: $primary;
  display: flex;
  flex: 1;
  font-size: 14px;
  height: 26px;
  max-width: 154px;
  padding: 0 8px;
}

.application-copy-addon {
  align-items: center;
  background-color: $primary;
  border-bottom-right-radius: 14px;
  border-top-right-radius: 14px;
  color: $white;
  display: flex;
  height: 26px;
  justify-content: center;
  width: 30px;
}

.profile-card {
  background-color: $white;
  color: $gray-900;

  @media print {
    box-shadow: none;

    .card-body {
      padding: 0;
    }
  }

  .degrees {
    margin-top: 10px;

    .degree-list {
      padding-left: 10px;
    }
  }

  .skill-list {
    margin-bottom: 20px;
    padding-left: 15px;
  }

  .icon,
  .flag-icon {
    margin-right: 9px;
    max-width: 25px;
  }

  .locomotion-icon-wrapper {
    text-align: center;
    width: 33px;

    .icon {
      max-height: 30px;
      max-width: 30px;
    }
  }

  .flag-icon {
    border-radius: 4px;
    width: 22px;
  }
}

.profile-card-header {
  display: grid;
  gap: $grid-gutter-width;

  @include mq($from: tablet) {
    grid-template-columns: 1fr 1fr;
  }
}

.public-profile-card {
  border-radius: 0;
  box-shadow: none;

  .card-body {
    padding: 1rem;
  }
}
