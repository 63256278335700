.homepage-path-wrapper {
  background-color: $pink-100;
  padding: $spacer * 3 0;
}

.homepage-path-cards {
  display: grid;
  gap: $grid-gutter-width;
  grid-template-columns: repeat(2, 1fr);
  margin: $grid-gutter-width auto;
  max-width: 600px;
}
