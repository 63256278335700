
@import '../../scss/config/config';
@import 'sass-mq/mq';

.step-back {
  @include mq($from: tablet) {
    font-size: 20px;
    font-weight: 700;
  }

  a {
    text-decoration: none;
  }
}

.content {
  display: flex;
  justify-content: space-between;
  margin-block: 20px;

  @include mq($from: tablet) {
    margin-block: 32px;
  }

  .title {
    display: none;
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 0;

    @include mq($from: tablet) {
      display: block;
    }

    @include mq($from: desktop) {
      font-size: 32px;
    }
  }

  .breadcrumb {
    margin-bottom: 0;

    @include mq($from: tablet) {
      width: 300px;
    }
  }
}
