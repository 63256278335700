
@use 'sass:math';
@import '../../scss/config/config';

.file-upload {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  .form-control {
    color: $white;
  }
}

.file-indicator {
  align-items: center;
  background-color: $cyan-100;
  border: 1px solid $steel;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  height: 30px;
  padding: 6px;
}

.file-name {
  font-size: 14px;
  margin-bottom: auto;
  margin-left: 10px;
  margin-top: auto;
}

