
@import '../../scss/config/config';
@import 'sass-mq/mq';

.store-switch-address-content {
  align-items: center;
  color: $body-color;
  display: flex;
  justify-content: space-between;
  padding: $spacer;

  &:not(:last-child) {
    border-bottom: 1px solid $gray-400;
  }
}

.store-switch-address {
  align-items: center;
  display: flex;
  gap: $spacer;
  position: relative;
}

.store-switch-address-indicator {
  width: 10px;
}

.store-switch-address-avatar {
  @include store-avatar(50px);
  display: inline;
}

.store-switch-address-title {
  font-family: $headings-font-family;
  font-size: 16px;
  font-weight: 900;
}

.store-switch-address-subtitle {
  color: $gray-500;
  font-size: 14px;
}

.bulletpoint {
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.mobile-hidden {
  display: none;

  @include mq($from: tablet) {
    display: block;
  }
}

.mobile-click {
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;

  @include mq($from: tablet) {
    display: none;
  }
}
