
@import '../../scss/config/config';
@import 'sass-mq/mq';

.app-affiliate-layout {
  background-color: #f5f4fb;
  min-height: 100vh;
  padding-top: 120px;

  @include mq($from: wide) {
    padding-top: 0;
  }
}

.app-affiliate-content {
  max-width: 100%;
  padding-inline: 20px;
  width: 1440px;

  @include mq($from: wide) {
    display: grid;
    grid-template-columns: 300px minmax(0, 1fr);
    min-height: 100vh;
    padding-inline: 0;
  }
}

.app-affiliate-sidebar {
  background-color: $gray-900;
}

.sticky-sidebar {
  padding: 20px;
  position: sticky;
  top: 0;
}

.sidebar-logo {
  margin-block: 32px 60px;
  width: 100%;
}
