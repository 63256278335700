
@import '../../scss/config/config';
@import 'sass-mq/mq';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';

// Mobile version of the "attract candidates" menu
@include offcanvas();

.printer-icon {
  fill: currentColor;
}

.qr-code-icon {
  margin: 3px 0 0 3px;
}

.store-switch-content {
  cursor: pointer;
}

.store-switch-wrapper {
  align-items: center;
  background: none;
  border: 0;
  color: $white;
  display: grid;
  flex: 1;
  gap: $spacer;
  padding: 0;
  text-align: left;

  @include mq($from: tablet) {
    grid-template-columns: 80px 1fr;
  }

  @include mq($from: desktop) {
    border-right: 1px solid #6861c2;
    margin-right: 50px;
    padding-right: $spacer;
  }

  &.store-switch-wrapper-essential {
    pointer-events: none;
  }
}

.address-first-letter {
  color: $blue;
  font-family: $headings-font-family;
}

.store-switch-avatar,
.addresses-page-avatar {
  @include store-avatar;
  border: 2px solid $secondary;
  color: $body-color;
  position: relative;

  .logo-wrapper {
    @include store-avatar;
    border: 2px solid $secondary;
    overflow: hidden;
  }

  .arrow-icon {
    bottom: -2px;
    position: absolute;
    right: -2px;
  }
}

.store-switch-title {
  font-family: $headings-font-family;
  font-size: 20px;
  font-weight: 700;
}

.store-switch-subtitle {
  font-size: 14px;

  @include mq($from: tablet) {
    font-size: 16px;
  }
}

// Modal
.store-switch-list {
  @include mq($from: tablet) {
    margin: 0;
    padding: 0 $spacer $spacer;
  }
}

.store-switch-search {
  margin: 30px 0 10px;
  max-width: 330px;
}

.store-switch-address-content-wrapper {
  max-height: 400px;
  overflow-y: auto;
}

.store-switch-address-content {
  &:not(:last-child) {
    border-bottom: 1px solid $gray-400;
  }
}

.store-switch-parent-address {
  background-color: rgb(246, 249, 252);
  border-radius: 10px;
}

// Print icon
.store-switch-print-links-wrapper {
  display: none;

  @include mq($from: desktop) {
    align-items: center;
    display: flex;
    gap: $spacer;
    justify-content: space-between;
  }
}

.store-switch-print-links-mobile-wrapper {
  align-self: center;
  display: block;

  @include mq($from: desktop) {
    display: none;
  }
}

.store-switch-print-links {
  display: none;

  @include mq($from: desktop) {
    display: flex;
    gap: $spacer;
    justify-content: center;
  }
}

.store-switch-print-link {
  font-size: 14px;
  line-height: $headings-line-height;
  max-width: 120px;
  text-align: center;

  .btn {
    @include button-variant(
      #6861c2, #6861c2, $white,
      $white, $white, $primary,
      $white, $white, $primary
    );
    align-items: center;
    aspect-ratio: 1;
    box-shadow: none;
    display: inline-flex;
    height: 42px;
    justify-content: center;
    margin-bottom: $spacer * .5;
    padding: 0;
    width: 42px;
  }
}

.btn-mobile-store-switch {
  align-items: center;
  aspect-ratio: 1;
  border-radius: 32px;
  display: flex;
  height: 32px;
  justify-content: center;
  padding: 0;
  width: 32px;
}

.store-switch-offcanvas-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: $spacer * 2;
}

.store-switch-offcanvas-link {
  color: $white;
  display: flex;
  gap: $spacer;
  margin-bottom: $spacer * 2;
  text-decoration: none;
}

.address-filter-form-control {
  border: 0;
  border-radius: 30px;
  box-shadow: 0 0 15px rgba(0, 0, 0, .15);
  margin: 30px 0 20px;
  min-width: 330px;
  padding: 13px 57px 13px 20px;
}

.svg-magnifier {
  position: absolute;
  right: 20px;
  top: 39px;
}

.button-download-qr-code {
  border: 1px solid #fff;
  color: #fff !important;
  line-height: 1;
  white-space: nowrap;
}

.store-switch-container {
  display: block;
  text-decoration: none;
  width: 100%;
}
