.app-affiliate-main {
  padding-bottom: 32px;

  @include mq($from: wide) {
    padding-inline: 32px;
  }

  @include mq($from: extra-wide) {
    padding-inline: 64px;
  }
}

.app-affiliate-header {
  display: none;

  @include mq($from: wide) {
    align-items: center;
    display: flex;
    gap: 16px;
    justify-content: space-between;
    margin-block: 40px 64px;
  }

  .h2 {
    font-size: 32px;
    font-weight: 600;
    margin: 0;
  }
}

.affiliate-info {
  + .affiliate-info {
    border-top: 1px solid $purple-200;
    margin-top: 12px;
    padding-top: 12px;
  }
}

.affiliate-info-link {
  align-items: center;
  color: $body-color;
  display: flex;
  font-size: 20px;
  justify-content: space-between;
  text-decoration: none;
}
