
@use 'sass:math';
@import '../../scss/config/config';
@import 'sass-mq/mq';

.professional-knowledge {
  margin-top: 32px;

  @include mq($from: tablet) {
    margin-top: 62px;
  }
}

.professional-soft-skill {
  margin-top: 32px;
}

.step3-forms {
  .form-check-group {
    gap: 8px;
  }

  .form-header {
    margin-block: 0 16px;

    .title {
      font-size: 20px;
    }

    .subtitle {
      color: $pink-500;
      font-size: 16px;
      font-weight: 400;
    }
  }
}

.form-check {
  align-items: center;
  display: flex;
  gap: 56px;
  justify-content: space-between;
  margin: 0;
  min-height: unset;
  padding: 0;

  + .form-check {
    border-top: 1px solid $steel;
    padding-top: 8px;
  }

  .form-check-input {
    float: unset;
    margin: 0;
  }

  .form-check-label {
    font-size: 16px;
    height: auto;
    text-align: left;
  }
}

.sticky-message {
  bottom: 6rem;

  @include mq($from: desktop) {
    bottom: 12rem;
  }
}
