*:has(> .surround) {
  position: relative;
  z-index: 1;
}

.surround {
  position: relative;
  white-space: nowrap;
  z-index: -1;

  img {
    left: 0;
    position: absolute;
    top: 0;
    transform: translate3d(-20%, -10%, 0);
    width: 160%;
    z-index: -1;
  }

  .surround-footer-home {
    transform: translate3d(-15%, -15%, 0);
    width: 130%;
  }

  .surround-candidate-hero {
    right: 0;
    transform: translate3d(6%, -10%, 0);
    width: 100%;
  }

  .surround-candidate-what {
    left: unset;
    right: 4%;
    transform: translate3d(0, -10%, 0);
    width: 95%;
  }

  .surround-candidate-how {
    transform: translate3d(0, -10%, 0);
    width: 100%;
  }

  .surround-candidate-why {
    transform: translate3d(10%, -15%, 0);
    width: 90%;
  }

  .surround-candidate-prefooter {
    transform: translate3d(-10%, 0, 0);
    width: 115%;
  }

  .surround-aff-hero {
    transform: translate3d(-10%, 0, 0);
    width: 115%;
  }

  .surround-aff-how {
    transform: translate3d(5%, -10%, 0);
    width: 110%;
  }

  .surround-aff-qrcode {
    transform: translate3d(-10%, -10%, 0);
    width: 130%;
  }

  .surround-aff-partner {
    transform: translate3d(-10%, -10%, 0);
    width: 130%;
  }

  .surround-aff-summary {
    transform: translate3d(10%, -10%, 0);
    width: 80%;
  }

  .surround-aff-footer {
    transform: translate3d(-10%, -20%, 0);
    width: 120%;
  }
}

.doodle {
  position: relative;

  .line {
    position: absolute;
    transform: translate3d(-100%, -30%, 0);
    width: clamp(18px, 3vw, 36px);
  }

  .flash {
    position: absolute;
    width: clamp(48px, 7vw, 152px);
  }

  .top {
    top: 0;
  }

  .bottom {
    bottom: 0;
  }

  .right {
    left: unset;
    right: 0;
  }

  .left {
    left: 0;
  }

  .flash-hero {
    transform: translate3d(40%, 90%, 0);
  }

  .flash-candidate-hero {
    transform: translate3d(40%, -12%, 0);
    width: clamp(28px, 7vw, 79px);
  }

  .line-candidate-how {
    transform: translate3d(-100%, 15%, 0);
    width: clamp(26px, 5vw, 46px);
  }

  .line-candidate-why {
    transform: translate3d(100%, -40%, 0) rotate(90deg);
    width: clamp(31px, 5vw, 52px);
  }

  .line-candidate-prefooter {
    transform: translate3d(-80%, -40%, 0);
    width: clamp(30px, 5vw, 54px);
  }

  .flash-candidate-prefooter {
    transform: translate3d(70%, -40%, 0);
  }

  .line-aff-hero {
    bottom: 8cqw;
    width: clamp(20px, 5vw, 54px);
  }

  .line-aff-how {
    top: 1cqw;
    width: clamp(26px, 3.5vw, 46px);
  }

  .flash-aff-how {
    transform: translate3d(60%, 40%, 0);
  }

  .flash-aff-qrcode {
    transform: translate3d(70%, 40%, 0);
  }

  .flash-aff-partner {
    transform: translate3d(110%, -20%, 0);
  }

  .flash-aff-summary {
    bottom: -5cqw;
    left: -6cqw;
    top: unset;
    transform: none;
    width: clamp(30px, 5vw, 98px);

    @include mq($from: mobile) {
      bottom: -3.5cqw;
      left: -4.5cqw;
    }
  }

  .line-aff-summary {
    transform: translate3d(-40%, -30%, 0) rotate(120deg);

    @include mq($from: extra-wide) {
      transform: translate3d(-140%, -30%, 0) rotate(120deg);
    }
  }
}

.candidate-hero {
  .doodle .line {
    transform: translate3d(-100%, 50%, 0);
    width: 60px;

    @include mq($until: desktop) {
      display: none;
    }
  }
}
