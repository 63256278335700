.affiliation-hero {
  text-align: center;

  @include mq($from: desktop) {
    text-align: left;
  }

  .hero-grid {
    display: grid;
    gap: 20px 40px;

    @include mq($from: desktop) {
      grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
      grid-template-rows: auto minmax(0, 1fr);
    }
  }

  .title {
    container-type: inline-size;
  }

  .h1 {
    color: var(--yellow-fluo);
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 13cqw;
    font-weight: 900;
    line-height: 1em;
    margin-left: 5%;
  }

  .img {
    justify-self: center;
    width: 145px;

    @include mq($from: mobile) {
      width: 30%;
    }

    @include mq($from: desktop) {
      grid-row: span 2;
      justify-self: end;
      width: auto;
    }
  }

  .subtitle-container {
    align-self: center;
    container-type: inline-size;

    @include mq($from: extra-wide) {
      align-self: end;
    }
  }

  .subtitle {
    font-size: 5cqw;
    font-weight: 900;
    letter-spacing: .4px;
    line-height: 1.5em;

    @include mq($from: desktop) {
      line-height: 1.2em;
    }
  }
}
