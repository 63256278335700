.not-found-image {
  max-height: 300px;
  padding: 1.5rem 0;

  @include mq($from: tablet) {
    max-height: 600px;
    width: 50%;
  }
}

.not-found-message {
  color: $dark-blue;
  font-size: 13px;

  @include mq($from: tablet) {
    font-size: 20px;
    font-weight: 700;
  }
}
