
@import '../../scss/config/config';
@import 'sass-mq/mq';

.btn {
  align-items: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr auto;
  padding: 20px;
  text-align: left;
  width: 100%;
}

.btn-white {
  &:focus {
    border-color: $primary;
    box-shadow: unset;
    color: $primary;
  }
}

.selected-item {
  border: 1px solid $primary;
}

.is-valid-field {
  background-color: $primary;
  color: #fff;
}
