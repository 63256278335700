.affiliation-partner {
  .h2 {
    @include mq($until: mobile) {
      font-size: 40px;
    }
  }
}

.partner-grid {
  background-color: #f8f7ec;
  border-radius: 16px;
  box-shadow: 0 0 5px 0 #00000029;
  display: grid;
  gap: 20px;
  justify-content: center;
  margin-inline: auto;
  margin-top: 80px;
  padding: 16px 14px;
  width: min(100%, 325px);

  @include mq($from: desktop) {
    gap: 32px;
    grid-template-columns: repeat(2, minmax(0, 325px));
    padding: 32px;
    width: 100%;
  }

  @include mq($from: extra-wide) {
    grid-template-columns: repeat(4, minmax(0, 325px));
  }

  .item {
    border: 1px solid #000;
    border-radius: 8px;
    padding: 22px;
  }

  .media {
    aspect-ratio: 285/245;
    background-color: #dbff67;
    border: 1px solid #000;
    padding: 30px 14px;
    text-align: center;
  }

  .img {
    height: auto;
    max-height: 100%;
    max-width: 100%;
  }

  .desc {
    font-size: 20px;
    font-weight: 700;
    margin-block: 20px 0;

    @include mq($from: extra-wide) {
      font-size: 24px;
    }
  }
}
