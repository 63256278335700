
@import '../../scss/config/config';
@import 'sass-mq/mq';

.resumes-filter {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: $grid-gutter-width * .5;
  margin: 15px 0;
}

.resumes-filter-label {
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
}

.resumes-header-filter {
  .dropdown-item {
    display: inline-flex;
    font-size: 14px;
    gap: 5px;
  }

  .form-check-input {
    cursor: pointer;
    margin-top: 2px;
  }
}

.standard-select {
  min-width: 280px;

  &::after {
    background-color: $purple;
  }

  .dropdown-toggle {
    color: $purple;
    text-align: left;

    &::after {
      content: none;
    }
  }
}
