.badge-rotate-left5 {
  transform: rotate(-5deg);
}

.badge-rotate-left1 {
  transform: rotate(-1deg);
}

.badge-rotate-right5 {
  transform: rotate(5deg);
}

.badge-rotate-right1 {
  transform: rotate(1deg);
}

.badge-rotate-right15 {
  transform: rotate(1.5deg);
}

.badge {
  line-height: unset;
}

.badge-dark-font {
  color: #271a41;
}

.badge-light-font {
  color: #ffe8fd;
}

.badge-light-blue {
  background-color: #ebeaf7;
  color: $black;
}

.badge-uppercase {
  text-transform: uppercase;
}

.badge-clippling {
  margin-bottom: 9px !important;
}
