
@import '../../scss/config/config';
@import 'sass-mq/mq';

.affiliate-btns {
  flex-direction: column;
  margin-top: 20px;

  @include mq($from: wide) {
    flex-flow: row wrap;
    margin-top: 0;
  }

  .btn {
    align-items: center;
    display: flex;
    gap: 12px;
    justify-content: center;

    svg {
      stroke: currentColor;
    }
  }
}
