.pagination {
  color: $dark-blue;
  margin-top: 25px;
}

.pagination-container {
  list-style: none;

  .pagination-item {
    align-items: center;
    border: 1px solid $steel;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    height: 32px;
    justify-content: center;
    width: 32px;

    &.active {
      background-color: $primary;
      border-color: $primary;

      .pagination-link {
        color: $white;
      }
    }

    .pagination-link {
      color: $steel;
      font-size: 14px;
      font-weight: 700;
      line-height: 1;
      text-decoration: none;
    }
  }

  .pagination-navigation a {
    color: $steel;
    cursor: pointer;
    padding: 10px;
    text-decoration: none;
  }
}

.pagination-item.active {
  background-color: #e2e4ea;
}
