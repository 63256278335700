
@import '../../scss/config/config';
@import 'sass-mq/mq';

.form-check {
  align-items: center;
  display: flex;
  gap: 56px;
  justify-content: space-between;
  margin: 0;
  min-height: unset;
  padding: 8px 0;

  + .form-check {
    border-top: 1px solid $steel;
  }

  .form-check-input {
    float: unset;
    margin: 0;
  }

  .form-check-label {
    font-size: 16px;
    height: auto;
    text-align: left;
  }
}

.form-subtitle {
  font-size: 20px;
  font-weight: 700;
  margin-block: 2rem 1rem;

  span {
    color: $pink-500;
    font-size: 16px;
    font-weight: 400;
  }
}

.invalid-message {
  bottom: 2rem;
  position: sticky;
}
