.application-activation-view .content {
  background: linear-gradient(180deg, $blue 400px, $white 400px);

  .card {
    height: 600px;
    margin: auto;
    max-width: 600px;
    width: 100%;
  }
}
