
@import '../../scss/config/config';
@import 'sass-mq/mq';

.return {
  background-color: transparent;
  border: 0;
}

.caces-mobility {
  margin-top: 32px;
}

.caces-grid {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

  .form-check-label {
    justify-content: center;
    width: 100%;
  }
}

.caces-modal {
  @include mq($until: tablet) {
    background-color: $light;
    left: 0;
    min-height: 100vh;
    padding: 32px;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
