
@import '../../scss/config/config';

@include job-card();

.job-card-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: .5rem;
  height: 100%;
}

.selected-values {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.value-badge {
  align-items: center;
  background-color: #d7d5d3;
  border-radius: 8px;
  color: #1d1d1f;
  display: flex;
  font-size: 12px;
  justify-content: center;
  padding: 4px 6px;
}

.job-card-cta {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: auto;

  .btn {
    font-size: 14px;
    padding: 0;
  }
}

// Stand by modal
.stand-by-title {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 44px;
}

.stand-by-content {
  margin-bottom: 44px;
}

.stand-by-cta {
  display: flex;
  gap: $grid-gutter-width;
  justify-content: center;
}

.btn-update {
  font-weight: inherit;
}

.underline-text {
  text-decoration: underline;
}

.custom-switchs {
  display: flex;
  gap: 22px;
  margin-bottom: 8px;
}

.job-card-top {
  display: flex;
  font-size: 16px;
  gap: 1rem;
  justify-content: space-between;
}

.job-card-name {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  height: 2lh;
  -webkit-line-clamp: 2;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
}

.job-card-actions {
  background-color: #fff;
  border-radius: 16px;
  bottom: 0;
  box-shadow: $card-box-shadow;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  padding: 1rem;
  position: absolute;
  right: 0;
  transform: translateY(calc(100% - 10px));
  width: 150px;
  z-index: 1;

  .btn-icon {
    align-items: center;
    color: $steel;
    display: grid;
    font-size: 14px;
    font-weight: 600;
    gap: 12px;
    grid-template-columns: 20px 1fr;
    text-align: left;
    text-decoration: none;
  }

  .delete {
    color: $pink-500;
  }
}

.active-badge {
  background-color: rgb(233, 14, 14);
  border-radius: 50%;
  display: inline-block;
  height: 12px;
  width: 12px;
}

.active-badge.active {
  background-color: #36dc51;
}

.job-card-footer {
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;
  justify-content: space-between;
  margin-top: auto;

  .urgent {
    color: $pink-500;
    font-weight: 700;
  }
}

.btn-actions {
  align-items: center;
  border: 1px solid currentColor;
  border-radius: 20px;
  color: $steel;
  display: flex;
  gap: 5px;
  height: 33px;
  justify-content: center;
  margin-left: auto;
  padding: 0;
  width: 63px;

  span {
    background-color: currentColor;
    border-radius: 50%;
    content: '';
    display: block;
    height: 5px;
    width: 5px;
  }
}

.business-unit {
  background-color: rgba(230, 223, 252, 1);
  border-radius: 8px;
  color: rgba(117, 86, 237, 1);
  display: inline-block;
  font-family: $headings-font-family;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;
  padding: 4px 8px;
  text-align: center;
  width: fit-content;
}
