
@import '../../scss/config/config';

.form-label {
  font-weight: 500;
}

.form-group {
  position: relative;
}

.form-control {
  background-color: $white !important;
  padding-right: 60px;
}

.dropdown-menu {
  background-color: $white;
  padding: 10px;
  position: absolute;
  top: 38px;
  width: 100%;
  z-index: 10;
}

.dropdown-item {
  background-color: transparent;
  border-width: 0;
  cursor: pointer;
  display: block;
  line-height: $headings-line-height;
  padding: 10px;
  text-align: left;
  white-space: normal;
  width: 100%;

  &:not(:first-child) {
    border-color: $gray-300;
    border-width: 1px 0 0;
    box-shadow: inset 0 1px 2px rgb(0, 0, 0, .08);
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $light-blue;
    border-radius: 15px;
    color: $primary;
  }
}

