.btn {
  font-weight: $btn-font-weight;
  transition: .2s ease-in all;

  .input-group & {
    background-color: $input-bg;
    border: 1px solid $input-border-color;
    border-radius: $input-border-radius;
    color: $input-color;

    &:not(.btn-xs):not(.btn-sm):not(.btn-lg) {
      padding: .5rem $input-btn-padding-x;
    }
  }

  &:disabled,
  &.disabled {
    opacity: 1;
  }

  &.done {
    background-color: $primary;
    color: $white;
  }
}

.btn-pink {
  @include button-variant(
    $pink-700, $pink-700, $white,
    $disabled-background: $white,
    $disabled-color: $pink-700
  );
}

.btn-blue {
  @include button-variant(
    $blue,
    $blue,
    $white,
    $disabled-background: $light-blue,
    $disabled-border: $light-blue,
    $disabled-color: $blue,
  );
}

.btn-primary {
  @include button-variant(
    $primary,
    $primary,
    $white,
    $disabled-background: $medium-blue,
    $disabled-border: $medium-blue,
    $disabled-color: $primary,
  );
}

.btn-dark-blue {
  @include button-variant($dark-blue, $dark-blue, $white);
}

.btn-white {
  @include button-variant($white, $white, $primary);
  box-shadow: none;
}

.btn-secondary {
  @include button-variant($secondary, $secondary, $white);
}

.btn-light {
  @include button-variant(
    $light,
    $light,
    $primary,
    $disabled-background: $light-blue,
    $disabled-border: $light-blue,
    $disabled-color: $blue,
  );
}

.btn-outline-white {
  @include button-outline-variant($white);
}

.btn-light-blue {
  @include button-variant(
    $medium-blue,
    $medium-blue,
    $primary,
    $disabled-background: $medium-blue,
    $disabled-border: $medium-blue,
    $disabled-color: $primary,
  );
  box-shadow: none;
}

.btn-light-purple {
  @include button-variant(
    $purple-200,
    $purple-200,
    $primary,
    $primary,
    $primary,
    $white
  );
}

.btn-rounded {
  border-radius: 50px;
}

.btn-icon {
  align-items: center;
  display: inline-flex;
  gap: $spacer * .5;
}

.btn-icon-w100 {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.btn-connection {
  background: $secondary;
  border-radius: 10px;
  color: $white;
  line-height: unset;
  padding: 11px 12px;
}

.btn-footer {
  background: #ffe5ff;
  border-radius: 20px;
  box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, .33);
  color: #3e3cac !important;
  margin-top: 20px;
  padding: 17px 25px;
}

.homepage-button-wrapper {
  text-align: center;

  @include mq($from: desktop) {
    text-align: left;
  }
}

.form-switch .btn-checked-red-to-green {
  background-color: #fff;
  background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$red-600}'/></svg>"));
  border-color: #00008b;
  height: 16px;

  &:checked {
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$teal-700}'/></svg>"));
  }
}

.form-check .btn-radio-inversed {
  background-color: #fff;
  border-color: #00008b;

  &:checked {
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#352bae'/></svg>"));

    + .label-text {
      color: $blue;
    }
  }
}

.btn-save {
  &:disabled {
    background-color: #352bae1a;
    color: $blue !important;
  }
}
