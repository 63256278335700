
@import '../../scss/config/config';
@import '~sass-mq/mq';

.brand-advantages-description {
  font-size: 14px;
}

.brand-advantages-category {
  margin-bottom: $spacer;
}

.brand-advantages-category-title {
  color: $primary;
  font-size: 16px;
  font-weight: 700;
}

.brand-advantages-category-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.brand-advantages-category-list-item {
  align-items: center;
  background-color: $white;
  border: 1px solid $white;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .07);
  display: flex;
  flex-direction: column;
  font-size: 10px;
  height: 92px;
  justify-content: center;
  line-height: $headings-line-height;
  position: relative;
  text-align: center;
  width: 75px;

  img,
  svg {
    margin-bottom: 5px;
  }
}

.brand-advantages-category-list-item-add {
  background-color: rgba(255, 14, 141, .15);
  color: $steel;
}

.brand-advantages-category-list-item-selected {
  border-color: $primary;
}

.brand-advantages-category-list-item-validate {
  position: absolute;
  right: -1px;
  top: -1px;
}

.brand-advantages-category-list-item-delete {
  background: transparent;
  border: 0;
  border-radius: 100%;
  padding: 0;
  position: absolute;
  right: 5px;
  top: 5px;
}

.advantage-modal-input {
  border: $blue-200;
  border-radius: 6pt;
}

.advantage-modal-save {
  border: hidden;
}

