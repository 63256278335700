.header {
  padding: 1.5rem 0;
  position: relative;
}

.homepage-hero-wrapper,
.header-404 {
  .header {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 5;
  }

  .topbar-header {
    background-color: $white;
    border-radius: 12px;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, .3);
    margin-inline: auto;
    padding: 10px 24px;
    width: fit-content;
  }

  .topbar-actions {
    color: $dark-blue;
    text-transform: none;

    .header-register-btn,
    .header-login-btn,
    .my-account-btn {
      color: inherit;
    }
  }
}
