
@import '../../scss/config/config';
@import 'sass-mq/mq';

.app-navigation {
  display: none;

  @include mq($from: desktop) {
    display: block;
    padding-inline: 16px;
  }

  @media print {
    display: none;
  }

  hr {
    height: 2px;
    margin: 0;
    opacity: 1;
  }
}

.app-navigation-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.app-navigation-link {
  align-items: center;
  border-radius: 8px;
  color: $white;
  display: flex;
  gap: 16px;
  min-height: 40px;
  padding: 10px 8px 8px;
  text-decoration: none;

  svg {
    width: 20px;
  }

  &.router-link-active {
    background-color: $white;
    color: $primary;
  }
}
