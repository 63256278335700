
@import 'sass-mq/mq';

.candidate-name {
  font-size: 14px;

  @include mq($from: tablet) {
    font-size: 20px;
  }
}

.match-percentage {
  font-size: 14px;
}
