.homepage-visible-wrapper {
  margin: 0 $grid-gutter-width * .5;
  padding: $spacer * 4 0 $spacer * 6;
  z-index: 3;

  @include mq($from: desktop) {
    margin: 0;
    padding: $spacer * 6 0;
    padding-top: $spacer * 15;
  }
}

.homepage-visible-content {
  position: relative;
}

.homepage-visible-content-title {
  color: $dark-blue;
  font-size: 20px;
  font-weight: 700;
  text-align: center;

  @include mq($from: mobile) {
    font-size: 28px;
  }

  @include mq($from: tablet) {
    font-size: 38px;
  }
}

.homepage-visible-glide {
  position: relative;
  z-index: 3;
}

.homepage-visible-card-slider {
  border-radius: 30px;
  max-height: 500px;
  max-width: 422px;

  @include mq($from: desktop) {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .3);
  }

  img {
    border-radius: 30px;
  }
}

.glide2 {
  position: relative;
  z-index: 3;

  // stylelint-disable selector-class-pattern
  .glide__slides {
    padding: 30px 0;
  }
}

.homepage-visible-controls {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.homepage-visible-controls-left,
.homepage-visible-controls-right {
  position: absolute;
  top: 50%;
  z-index: 4;
}

.homepage-visible-controls-right {
  right: 15px;
}

.homepage-visible-controls-left {
  left: 15px;
}

.slider2-arrow-left,
.slider2-arrow-right {
  background: $primary;
  border-radius: 50%;
  cursor: pointer;
  height: 50px;
  padding: 16px 19px;
  width: 50px;
}
