
@import '../../scss/config/config';
@import 'sass-mq/mq';

.bread-crumb {
  margin-block: 32px;
  width: 100%;

  @include mq($from: tablet) {
    margin-block: 60px;
  }

  .progress {
    flex-grow: 1;
  }

  .current-step {
    color: $steel;
    font-size: 14px;
    font-weight: 700;
  }
}

.step-back {
  background: transparent;
  border: 0;
  padding: 0;
}

.step-back-icon {
  height: 24px;
  margin-bottom: 16px;
  width: 28px;
}

.breadcrumb-indicators {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
}
