
@import '../../scss/config/config';
@import 'sass-mq/mq';

.app-header {
  display: grid;
  gap: 24px;
  grid-template-columns: 27px auto;
  padding: 2rem 0 1rem;

  @include mq($from: desktop) {
    grid-template-columns: auto;
    padding: 2rem 0;
  }

  @media print {
    display: none;
  }
}

.logo {
  color: $white;
  padding-left: 20px;
}

.notification {
  display: flex;
  justify-content: flex-end;
}
