@font-face {
  font-display: swap;
  font-family: AktiveGrotesk;
  font-style: normal;
  font-weight: 400;
  src:
    url('../fonts/AktiveGrotesk/AktivGrotesk-Regular.woff2') format('woff2'),
    url('../fonts/AktiveGrotesk/AktivGrotesk-Regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: AktiveGrotesk;
  font-style: normal;
  font-weight: 500;
  src:
    url('../fonts/AktiveGrotesk/AktivGrotesk-Medium.woff2') format('woff2'),
    url('../fonts/AktiveGrotesk/AktivGrotesk-Medium.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: AktiveGrotesk;
  font-style: normal;
  font-weight: 700;
  src:
    url('../fonts/AktiveGrotesk/AktivGrotesk-Bold.woff2') format('woff2'),
    url('../fonts/AktiveGrotesk/AktivGrotesk-Bold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: AktiveGrotesk;
  font-style: normal;
  font-weight: 800;
  src:
    url('../fonts/AktiveGrotesk/AktivGrotesk-XBold.woff2') format('woff2'),
    url('../fonts/AktiveGrotesk/AktivGrotesk-XBold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: AktiveGrotesk;
  font-style: italic;
  font-weight: 400;
  src:
    url('../fonts/AktiveGrotesk/AktivGrotesk-Italic.woff2') format('woff2'),
    url('../fonts/AktiveGrotesk/AktivGrotesk-Italic.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: AktiveGrotesk;
  font-style: italic;
  font-weight: 700;
  src:
    url('../fonts/AktiveGrotesk/AktivGrotesk-BoldItalic.woff2') format('woff2'),
    url('../fonts/AktiveGrotesk/AktivGrotesk-BoldItalic.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: AktiveGrotesk;
  font-style: italic;
  font-weight: 800;
  src:
    url('../fonts/AktiveGrotesk/AktivGrotesk-XBoldItalic.woff2') format('woff2'),
    url('../fonts/AktiveGrotesk/AktivGrotesk-XBoldItalic.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: NunitoRegular;
  font-style: normal;
  font-weight: 400;
  src:
    url('../fonts/Nunito/nunito-regular.woff2') format('woff2'),
    url('../fonts/Nunito/nunito-regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: NunitoSemiBold;
  font-style: normal;
  font-weight: 600;
  src:
    url('../fonts/Nunito/nunito-semiBold.woff2') format('woff2'),
    url('../fonts/Nunito/nunito-semiBold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/BarlowCondensed/barlow-condensed-v12-latin-700.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/BarlowCondensed/barlow-condensed-v12-latin-900.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Roboto/roboto-v32-latin-regular.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Roboto/roboto-v32-latin-500.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Roboto/roboto-v32-latin-700.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/Roboto/roboto-v32-latin-900.woff2') format('woff2');
}
