.add-address-job {
  background-color: $white;
  border-radius: 16px;
  color: $body-color;
  padding: 32px 20px;

  @include mq($from: tablet) {
    padding-inline: clamp(40px, 6vw, 80px);
  }

  .form-title {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  :where(.experience, .languages, .licence-mobility, .personal-quality) {
    .form-check-group {
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
      margin-top: 32px;
    }

    .form-check-label {
      font-size: 20px;
      justify-content: center;
      min-height: 74px;
      text-align: center;
      width: 100%;
    }
  }
}

.add-address-job-main {
  margin-inline: auto;
  max-width: 100%;
  width: 520px;
}

.add-job-btns {
  display: flex;
  flex-direction: column;
  gap: 16px 24px;
  justify-content: end;
  margin-top: 20px;

  @include mq($from: tablet) {
    flex-direction: row-reverse;
    flex-wrap: wrap;

    .btn {
      min-width: 300px;
    }
  }
}
