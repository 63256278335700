.content-profiles {
  display: grid;
  font-family: $special-font, $font-family-sans-serif;
  gap: 27px;
  margin-inline: auto;
  max-width: 520px;
  width: 100%;

  @include mq($from: desktop) {
    gap: 56px;
  }

  .profile {
    --box-shadow-size: 10px 10px 6px 0;
    background-color: $white;
    border: 1px solid $gray-900;
    border-radius: 25px;
    box-shadow: var(--box-shadow-size) #1d1d1f99;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    position: relative;

    @include mq($from: mobile) {
      padding-inline: 64px;
    }

    @include mq($from: desktop) {
      --box-shadow-size: 20px 20px 6px 0;
      border-radius: 50px;
      max-width: calc(100% - 64px);
    }

    &.dark {
      background-color: $gray-900;
      box-shadow: var(--box-shadow-size) #fffc;
      color: $white;
    }
  }

  .bubble {
    aspect-ratio: 1;
    position: absolute;
    right: -5px;
    top: -8px;
    width: 55px;

    @include mq($from: desktop) {
      right: -20px;
      top: -25px;
      width: 78px;
    }
  }
}

.profile-price {
  align-items: end;
  display: flex;
  font-weight: 900;
  gap: 30px;
  justify-content: center;
  margin-bottom: 16px;

  @include mq($from: wide) {
    gap: 12px;
  }

  .info {
    display: flex;
    flex-direction: column;
  }

  .value {
    font-size: 120px;
    line-height: 1em;

    @include mq($from: wide) {
      font-size: 150px;
    }

    @include mq($from: extra-wide) {
      font-size: 170px;
    }
  }

  .currency {
    font-family: $font-family-sans-serif;
    font-size: 90px;
    font-weight: 500;
    line-height: .8em;

    @include mq($from: wide) {
      font-size: 135px;
    }
  }

  .unity {
    font-size: 26px;
    text-transform: uppercase;

    @include mq($from: extra-wide) {
      font-size: 32px;
    }
  }
}

.fake-btn {
  align-items: center;
  background-color: $gray-900;
  border-radius: 30px;
  color: $white;
  display: flex;
  font-family: $font-family-sans-serif;
  font-size: 14px;
  font-weight: 700;
  justify-content: center;
  min-height: 40px;
  padding: 10px 20px;
  text-transform: uppercase;

  @include mq($from: wide) {
    font-size: 18px;
    min-height: 60px;
  }

  .dark & {
    background-color: $white;
    color: $gray-900;
  }
}
