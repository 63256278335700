
@import '../../scss/config/config';
@import 'sass-mq/mq';

.form-group {
  position: relative;
}

.dropdown-menu {
  background-color: $white;
  padding: 10px;
  position: absolute;
  top: 78px;
  width: 100%;
  z-index: 10;
}

.dropdown-item {
  background-color: transparent;
  border-width: 0;
  cursor: pointer;
  display: block;
  padding: 10px;
  text-align: left;
  white-space: normal;
  width: 100%;

  &:not(:first-child) {
    border-color: $gray-300;
    border-width: 1px 0 0;
    box-shadow: inset 0 1px 2px rgb(0, 0, 0, .08);
  }
}

.new-job-sections {
  display: flex;
  flex-direction: column;
  gap: 30px;

  @include mq($from: tablet) {
    flex-direction: row;
  }
}

.step-number {
  border-radius: 50%;
  display: block;
  font-size: 20px;
  height: 30px;
  text-align: center;
  width: 30px;
}

.active-step {
  font-size: 20px;
  font-weight: bold;

  .step-number {
    background: rgb(53, 43, 174);
    color: $white;
  }
}

.passive-step {
  .step-number {
    background: $white;
    border: 1px solid rgb(53, 43, 174);
    color: rgb(53, 43, 174);
  }
}

.step-section {
  display: flex;
  gap: 20px;
  justify-content: end;

  @include mq($from: tablet) {
    border-right: 1px solid rgb(215, 213, 211);
    flex-direction: column;
    justify-content: unset;
    padding-right: 64px;
    width: 61%;
  }
}

.new-job-form {
  @include mq($from: tablet) {
    padding-left: 64px;
    width: 100%;
  }
}

.new-job-step {
  align-items: center;
  background: none;
  border: 0;
  cursor: default;
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
  text-align: left;

  &:hover {
    text-decoration: none;
  }
}

.form-check-group {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.custom-check {
  display: inline-block;
}

.custom-check .new-job-radio {
  display: none;
}

.custom-check label {
  border: 2px solid #ccc;
  border-radius: 10px;
  cursor: pointer;
  display: inline-block;
  padding: 10px 20px;
}

.custom-check .new-job-radio:checked + label {
  background-color: $black;
  color: $white;
}

.mobile-steps {
  font-size: 20px;
  text-align: right;
}

.btn-primary:disabled {
  background-color: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
}

.new-job-title {
  font-size: 22px;
  margin-bottom: 24px;

  @include mq($from: tablet) {
    font-size: 30px;
    margin-bottom: 64px;
  }
}

.new-job-buttons {
  justify-content: center;
  margin-top: 24px;

  @include mq($from: tablet) {
    justify-content: end;
    margin-top: 64px;
  }
}
