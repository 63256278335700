
@import '../../scss/config/config';
@import 'sass-mq/mq';

.order-affiliate-kit-infos {
  .title {
    color: $pink-700;
    font-size: 24px;
    font-weight: 700;

    @include mq($from: desktop) {
      font-size: 32px;
      margin-top: 64px;
    }
  }
}

.kit-infos {
  background-color: $white;
  border-radius: 8px;
  margin-top: 32px;
  padding: 16px;

  @include mq($from: desktop) {
    background-color: transparent;
    padding: 0;
  }
}

.kit-toggle[type=button] {
  align-items: center;
  background-color: transparent;
  border: 0;
  color: $primary;
  display: flex;
  font-size: 20px;
  font-weight: 700;
  justify-content: space-between;
  padding: 0;
  width: 100%;

  @include mq($from: desktop) {
    cursor: default;
  }

  .toggle-icon {
    height: 13px;
    transition: all .3s;
    width: 20px;

    @include mq($from: desktop) {
      display: none;
    }
  }

  &.open {
    .toggle-icon {
      transform: rotate(180deg);
    }
  }
}

.kit-content {
  display: none;

  @include mq($from: desktop) {
    display: block;
  }

  &.visible {
    display: block;
  }
}

.kit-items {
  list-style-type: none;
  margin: 16px 0 0;
  padding: 0;
}

.kit-item {
  align-items: center;
  display: grid;
  gap: 11px;
  grid-template-columns: 13px minmax(0, 1fr);
  line-height: 1em;

  + .kit-item {
    margin-top: 8px;
  }

  svg {
    width: 13px;
  }
}

.invalid-message {
  align-items: start;
  color: $body-color;
  margin-top: 16px;
}
