.footer {
  background-color: $gray-900;
  color: $white;
  padding: 2rem 0 5rem;
  text-align: center;

  @include mq($from: desktop) {
    border-top: 1px solid #eceadc;
    padding-top: 60px;

    .landing-candidate & {
      border-top: 0;
    }
  }

  @include mq($from: wide) {
    padding-top: 90px;
  }

  a {
    color: $white;
  }
}

.footer-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;

  @include mq($from: desktop) {
    align-items: start;
    flex-direction: row;
    gap: 50px;
  }

  @include mq($from: extra-wide) {
    align-items: center;
  }

  .footer-logo {
    width: 162px;

    @include mq($from: extra-wide) {
      width: 316px;
    }
  }
}

.footer-copyright {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  gap: 10px;
  justify-content: center;

  @include mq($from: tablet) {
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }

  @include mq($from: wide) {
    font-size: 16px;
    gap: 40px;
  }
}

.footer-copyright-link {
  align-items: center;
  display: flex;
  gap: 5px;
  text-decoration: none;

  .svg-facebook-dims,
  .svg-linkedin-dims {
    height: 24px;
    width: 24px;
  }
}

.footer-round-check {
  align-items: center;
  display: flex;
  height: 100%;
}
