
@import '../../scss/config/config';

.business-unit-card {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  position: relative;
  text-align: center;

  .card-body {
    padding: 25px 0 0;
  }
}

.business-unit-card-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.business-unit-name {
  color: $gray-900;
  font-size: 20px;
}

.business-unit-jobs {
  color: #808080;
  font-size: 14px;
}

.btn-delete {
  background: none;
  border: 0;
  color: $primary;
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 10px;
}
