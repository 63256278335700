
@use 'sass:math';
@import '../../scss/config/config';
@import 'sass-mq/mq';

.step3-forms {
  .personal-qualities {
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @include mq($from: mobile) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  .form-check-label {
    font-size: 16px;
    height: 50px;
  }
}

.subtitle span {
  color: $pink-500;
}

.sticky-message {
  bottom: 6rem;

  @include mq($from: mobile) {
    position: unset;
  }
}
