.homepage-video {
  background-color: $white;
  margin-top: 2rem;
  padding: 20px 0;
  position: relative;
  z-index: 2;
}

.homepage-video-iframe {
  margin: 0 auto;
  max-width: 960px;

  video {
    border-radius: 30px;
  }
}
