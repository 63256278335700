
@import '../../scss/config/config';
@import 'sass-mq/mq';

.add-address-job .form-check-group {
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));

  .form-check-label {
    font-size: 16px;
    min-height: 70px;
  }
}
