
@import '../../scss/config/config';
@import 'sass-mq/mq';

.app-footer {
  border-top: 1px solid $gray-900;
  color: $body-color;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  gap: 10px 30px;
  justify-content: center;
  margin-top: auto;
  padding: 18px 0 40px;
  position: relative;

  @media print {
    display: none;
  }

  a {
    color: $body-color;
    text-decoration: none;
  }

  .svg-dw {
    height: 20px;
    width: 32px;
  }
}

.app-footer-logo {
  display: inline-flex;
  gap: 20px;

  .svg-dw {
    margin-top: -4px;
    position: relative;
  }
}

.app-footer-nav {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 30px;
  justify-content: center;
  margin-bottom: 0;

  .svg-facebook,
  .svg-linkedin {
    height: 24px;
    width: 24px;
  }
}

.app-footer-follow-title {
  margin-right: 10px;
}

.app-footer-follow-links {
  display: inline-flex;
  gap: 20px;
}
