
@import '../../scss/config/config';
@import 'sass-mq/mq';

.invoices-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  list-style-type: none;
  margin: 32px 0 0;
  max-width: 100%;
  padding: 0;
  width: 500px;
}

.invoice-item {
  align-items: start;
  display: flex;
  gap: 10px;

  .number {
    font-weight: 700;
    line-height: 1em;
    margin-bottom: 0;
  }

  .date {
    color: $steel;
    font-size: 14px;
    margin-bottom: 0;
  }

  .btn {
    align-items: center;
    display: flex;
    height: 34px;
    justify-content: center;
    margin-left: auto;
    padding: 0;
    width: 34px;
  }
}
