.summary-list {
  list-style-type: none;
  margin: 80px 0 30px;
  padding: 0;
  text-align: left;

  @include mq($from: tablet) {
    margin-inline: auto;
    margin-top: 100px;
    width: max-content;
  }

  @include mq($from: desktop) {
    margin-top: 140px;
  }

  li {
    --marker-width: 16px;

    display: grid;
    gap: 8px;
    grid-template-columns: var(--marker-width) minmax(0, 1fr);

    @include mq($from: desktop) {
      --marker-width: 20px;

      gap: 16px;
    }

    @include mq($from: extra-wide) {
      --marker-width: 23px;
    }

    + li {
      margin-top: 10px;
    }
  }

  img {
    aspect-ratio: 1;
    background-color: #ad94f6;
    border-radius: 50%;
    margin-top: 3px;
    padding: 3px;
    width: var(--marker-width);

    @include mq($from: desktop) {
      padding: 5px;
    }

    @include mq($from: extra-wide) {
      margin-top: 6px;
      padding: 6px;
    }
  }
}

.aff-summary-img {
  display: block;
  margin-inline: auto;
  max-width: 100%;
  width: 253px;

  @include mq($from: mobile) {
    width: auto;
  }

  @include mq($from: desktop) {
    margin-block: 38px;
  }
}

.affiliation-summary {
  container-type: inline-size;

  .desc {
    margin-block: 30px 40px;
    text-align: center;
    text-wrap: balance;
  }
}

.inscription {
  border-top: 1px solid $black;
  padding-block: 30px 10px;

  @include mq($from: desktop) {
    margin-inline: 50px;
    padding-top: 60px;
  }

  .btn {
    align-items: center;
    background-color: $black;
    display: flex;
    font-weight: 500;
    gap: 6px;
    height: 41px;
    line-height: 1em;
    margin-inline: auto;
    padding: 10px 18px;
    width: fit-content;

    @include mq($from: desktop) {
      font-size: 19px;
      gap: 16px;
      height: 85px;
      padding-inline: 40px;
    }

    img {
      width: 20px;

      @include mq($from: desktop) {
        width: 30px;
      }
    }
  }
}

.inscription-title {
  font-family: var(--body-font-family);
  font-size: 16px;
  font-weight: 400;
  text-align: center;

  @include mq($from: desktop) {
    font-size: 25px;
  }

  span {
    font-weight: 700;

    @include mq($from: desktop) {
      font-size: 40px;
    }
  }
}

.inscription-grid {
  display: grid;
  gap: 20px;
  margin-block: 25px 30px;
  margin-inline: auto;
  max-width: 245px;

  @include mq($from: desktop) {
    gap: 30px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-block: 50px 80px;
    max-width: 800px;
    width: fit-content;
  }
}

.slogan {
  color: $white;
  font-family: $special-font, $font-family-base;
  font-size: 35px;
  font-weight: 700;
  line-height: 45px;
  margin-block: 52px 0;
  text-align: center;
  text-wrap: pretty;

  @include mq($from: mobile) {
    font-size: 5cqw;
    font-weight: 900;
    line-height: 1.1em;
  }

  @include mq($from: desktop) {
    margin-top: 90px;
  }

  @include mq($from: extra-wide) {
    margin-top: 140px;
  }
}
