
@use 'sass:math';
@import '../../scss/config/config';
@import 'sass-mq/mq';

.missing-purchases {
  color: $body-color;
  margin-inline: auto;
  max-width: 100%;
  padding: 20px;
  text-align: center;
  width: 400px;

  @include mq($from: mobile) {
    padding-inline: 0;
  }

  .title {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .subtitle {
    margin-bottom: 32px;
  }
}
