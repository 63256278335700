
@use 'sass:math';
@import '../../scss/config/config';

.contact-us-card {
  background-color: $white;
  border-radius: math.div($grid-gutter-width, 2);
  box-shadow: 0 0 math.div($grid-gutter-width, 2) 0 rgba(0, 0, 0, .15);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 56px 20px 40px;
}

.contact-us-title {
  font-size: 22px;
  font-weight: 300;
}

.contact-us-franchises {
  color: $gray-900;
  font-size: 26px;
  font-weight: 500;
  line-height: $headings-line-height;
}
