
@import '../../scss/config/config';
@import '~sass-mq/mq';

.brand-navigation {
  font-size: 13px;
  font-weight: 700;

  @include mq($from: tablet) {
    font-size: 22px;
  }
}

.brand-navigation-list {
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: space-around;

  @include mq($from: tablet) {
    align-items: baseline;
    justify-content: space-between;
  }

  li {
    line-height: $headings-line-height;
    text-align: center;

    @include mq($from: tablet) {
      text-align: left;
    }
  }
}

.brand-navigation-list-link {
  opacity: .5;
  text-decoration: none;

  &.active {
    opacity: 1;
    text-decoration: underline;
  }
}
