
@import '../../scss/config/config';
@import 'sass-mq/mq';

.add-address-job .form-check-group {
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  margin-top: 1rem;

  .form-check-label {
    font-size: 16px;
    gap: 10px;
    min-height: 70px;
    text-align: left;
  }

  .form-check:last-of-type {
    grid-column: 1/-1;
  }

  .btn-caces {
    background-color: transparent;
    justify-content: space-between;
    text-transform: uppercase;
  }

  .licence-caces {
    align-items: center;
    display: flex;
    gap: 10px;
  }

  .is-valid-field {
    background-color: $purple-200;
    border-color: $primary;
    color: $primary;
  }
}

.form-subtitle {
  font-size: 20px;
  font-weight: 500;
  margin-block: 2rem 1rem;
}
