/* stylelint-disable selector-class-pattern */
.v-toast__item {
  border-radius: 1rem;
  min-height: 50px;
  padding: 1rem;
}

.v-toast__text {
  margin: 0;
  padding: 0;
}

.v-toast__item--success {
  background-color: $primary;
}
/* stylelint-enable */
