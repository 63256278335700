@mixin logo() {
  color: $white;
  font-family: $headings-font-family;
  font-size: 22px;
  font-weight: 800;
  line-height: 1;
  max-width: 180px;

  @include mq($from: tablet) {
    font-size: 36px;
  }

  @include mq($from: wide) {
    max-width: 220px;
  }

  img {
    width: 100%;
  }

  a {
    color: currentColor;
    text-decoration: none;
  }
}

@mixin offcanvas() {
  .offcanvas {
    background-color: $primary;
    color: $white;
  }

  .btn-offcanvas {
    box-shadow: none;
  }

  .btn-close {
    background: transparent url('../../images/svg/close.svg') no-repeat center;
    background-size: 21px 21px;
    right: 1rem;
    top: 1rem;
  }
}

@mixin job-card() {
  .job-card {
    display: flex;
    min-height: 140px;

    .card-body {
      align-items: center;
      display: flex;
      padding: 15px;
      position: relative;
    }
  }

  .job-card-name {
    font-family: $headings-font-family;
    font-size: 18px;
    font-weight: 700;
    line-height: $headings-line-height;
    padding-right: 20px;
  }

  .job-card-delete {
    right: 15px;
    text-decoration: none;
    top: 15px;
  }
}

@mixin store-avatar($size: 80px) {
  display: none;

  @include mq($from: tablet) {
    align-items: center;
    background-color: $light;
    border-radius: $size;
    display: flex;
    flex-shrink: 0;
    height: $size;
    justify-content: center;
    width: $size;

    img {
      object-fit: cover;
      padding: 5px;
    }
  }
}

@mixin register-background($container-size, $gap) {
  background:
    linear-gradient(
      to right,
      $light-blue 0%,
      $light-blue calc((100vw - #{$container-size}) / 2 + (#{$container-size} * .75) - #{$gap * .5}),
      $white calc((100vw - #{$container-size}) / 2 + (#{$container-size} * .75) - #{$gap * .5}),
      $white 100%
    );
}

@mixin make-register() {
  .register-addresses {
    column-gap: $grid-gutter-width * 3;
    display: grid;

    @include mq($from: desktop) {
      grid-template-columns: minmax(0, 75%) minmax(0, 25%);
    }
  }

  .register-grid-left {
    @include mq($from: tablet) {
      color: #4d4d4d;
      column-gap: $grid-gutter-width * 2;
      display: grid;
      grid-template-columns: 196px minmax(0, 1fr);
    }

    .img-container {
      display: none;

      @include mq($from: tablet) {
        display: block;
      }
    }
  }

  .required-fields {
    color: $gray-900;
  }

  .register-addresses-title-block {
    grid-column: 1 / span 2;
  }

  .register-addresses-summary {
    background-color: $white;

    @include mq($from: desktop) {
      position: sticky;
      top: $grid-gutter-width;
    }
  }

  .register-addresses-advantages {
    color: $dark-blue;
    font-size: 14px;
    margin-top: $spacer;
  }

  .register-back-title {
    color: $primary;
    font-size: 20px;
    font-weight: 700;

    a {
      text-decoration: none;
    }
  }
}
