
@import '../../scss/config/config';
@import 'sass-mq/mq';

.wave {
  display: none;

  @include mq($from: desktop) {
    bottom: calc(100vh - 711px);
    display: inline-block;
    left: calc(-50vw + 50%);
    position: absolute;
    width: 100vw;
    z-index: auto;
  }
}

.app-layout {
  background: linear-gradient(180deg, $primary 500px, $light 500px);
  color: $white;

  @include mq($from: desktop) {
    background: // url('../../images/wave.svg') center top 710px no-repeat,
      linear-gradient($primary calc(100vh - var(--blue-height)), $light calc(100vh - var(--blue-height)));
    background-size: 101vw;
  }

  .container.layout-container {
    max-width: 1400px;
    min-height: 100vh;
  }
}

.app-layout-content {
  display: grid;
  gap: 25px;
  grid-template-columns: minmax(0, 1fr);
  padding-bottom: 90px;
  z-index: 2;

  @include mq($from: desktop) {
    grid-template-columns: max-content minmax(0, 1fr);
  }

  @media print {
    grid-template-columns: minmax(0, 1fr);
  }

  .modal-open & {
    z-index: auto;
  }
}

.btn-logout {
  white-space: nowrap;
}
