.scrolldown {
  border: 2px solid $white;
  border-radius: 30px;
  height: 46px;
  margin: 0 auto 8px;
  text-align: center;
  width: 30px;
}

.scrolldown-p1,
.scrolldown-p2 {
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: scrolldown;
  fill: $white;
}

.scrolldown-p2 {
  animation-delay: .75s;
}

@keyframes scrolldown {
  0% {
    opacity: 0;
    transform: translate(0, -8px);
  }

  50% {
    opacity: 1;
    transform: translate(0, 0);
  }

  100% {
    opacity: 0;
    transform: translate(0, 8px);
  }
}
