.text-xs {
  font-size: 12px;
}

.text-s {
  font-size: 14px;
}

.text-m {
  font-size: 16px;
}

.text-l {
  font-size: 18px;
}

.text-xl {
  font-size: 22px;
}

.text-xxl {
  font-size: 28px;
}

.text-xxxl {
  font-size: 32px;
}

.fw-700 {
  font-weight: 700;
}

.text-pink {
  color: $pink;
}
