:root {
  --blue-height: calc(100vh - 709px);
}

.body {
  display: flex;
  flex-direction: column;
  font-size: $font-size-base;
  min-height: 100vh;
  overflow-x: hidden;
}

.content {
  flex: 1;
}

.bg-innovative {
  background: $pink-300;
}

.bg-visible {
  background: #ef4397;
}

.bg-contact {
  background: #5d66de;
}

.empty-state-content {
  min-height: 300px;
}

.invalid-message {
  align-items: center;
  background-color: $pink-200;
  border-radius: 16px;
  color: $steel;
  display: flex;
  font-size: 14px;
  gap: 12px;
  margin-top: 32px;
  padding: 15px;

  svg {
    min-width: 20px;
  }
}

.sticky-message {
  bottom: 2rem;
  position: sticky;
}

/* stylelint-disable */
.glowCookies__banner.glowCookies__banner__1 {
  max-width: 430px;
}
/* stylelint-enable */
