.topbar-header {
  display: grid;
  gap: $spacer;
  justify-content: center;
}

.topbar-actions {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0;
  justify-content: center;

  @include mq($from: desktop) {
    flex-direction: row;
    justify-content: flex-end;
  }

  @include mq($from: wide) {
    gap: $spacer;
  }

  .btn {
    font-weight: 500;
    padding: 11px 12px;

    @include mq($until: mobile) {
      padding: $btn-padding-y-sm $btn-padding-x-sm;
    }
  }
}
