.view-cart {
  .app-layout {
    background: #f5f4fb;
    color: $body-color;
  }

  .app-header {
    background-color: $primary;
    position: relative;

    &::before {
      background-color: $primary;
      content: '';
      height: 100%;
      left: 50%;
      margin-left: -50vw;
      position: absolute;
      width: 100vw;
    }

    > * {
      position: relative;
    }
  }

  .app-layout-content {
    grid-template-columns: minmax(0, 1fr);
  }

  .app-navigation {
    @include mq($from: desktop) {
      display: none;
    }
  }
}

.cart-header {
  margin-block: 32px;

  &.confirmed {
    text-align: center;

    @include mq($from: mobile) {
      text-align: left;
    }

    .h2 {
      align-items: center;
      display: flex;
      flex-direction: column;

      @include mq($from: mobile) {
        flex-direction: row;
        gap: 32px;
      }
    }
  }

  .h2 {
    font-size: 20px;
    gap: 16px;
    margin-bottom: 16px;

    @include mq($from: desktop) {
      font-size: 32px;
    }

    svg {
      background-color: $white;
      border-radius: 50%;
      height: 40px;
      padding: 10px;
      width: 40px;
    }
  }

  .subtitle {
    color: $steel;
    font-size: 20px;
    margin: 0;
  }
}

.cart-grid {
  align-items: start;
  display: grid;
  gap: 32px;
  grid-template-columns: minmax(0, 1fr);

  &:has(.empty) {
    align-items: stretch;
  }

  @include mq($from: tablet) {
    grid-template-columns: minmax(0, 1fr) 396px;
  }
}

.cart-details {
  @include mq($from: tablet) {
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);
    color: $body-color;
    padding: 32px 20px;
  }

  &.empty {
    @include mq($from: mobile) {
      text-align: center;
    }

    .img {
      margin-top: 32px;
    }
  }

  .title {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .candidates-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: 0;

    @include mq($from: mobile) {
      grid-template-columns: repeat(auto-fill, minmax(164px, 1fr));
    }

    @include mq($from: desktop) {
      grid-template-columns: repeat(3fr, 1fr);
    }
  }
}

.candidate-card-cart {
  display: flex;
  flex-direction: column;

  .delete-candidate {
    background-color: transparent;
    border: 0;
    color: $pink-700;
    font-size: 14px;
    font-weight: 500;
    margin-left: auto;
    margin-top: 4px;
    padding: 0;

    &:hover {
      color: $primary;
    }
  }
}

.candidates-group {
  margin-top: 32px;

  .h5 {
    margin-bottom: 16px;
  }
}

.candidates-group-header {
  color: $body-color;
  padding-inline: 20px;

  .title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  .date {
    margin: 0;
  }
}

.order-resume {
  display: grid;
  gap: 32px;
  grid-template-columns: minmax(0, 1fr);
  margin-inline: auto;
  max-width: 396px;
  position: sticky;
  top: 32px;
  width: 100%;
}

.order-reassurance,
.order-details {
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);
  color: $steel;
  padding: 20px;
}

.order-details {
  font-size: 16px;

  hr {
    margin-block: 16px;
  }
}

.order-details-row {
  line-height: 1;

  &:first-child {
    margin-bottom: 16px;
  }

  + .order-details-row {
    margin-top: 10px;
  }

  &.total {
    color: $body-color;
  }
}

.order-resume-action {
  .btn {
    margin-top: 32px;
    width: 100%;
  }
}

.order-reassurance {
  display: grid;
  gap: 16px;

  .item {
    display: grid;
    font-size: 14px;
    gap: 16px;
    grid-template-columns: 30px auto;
  }

  p {
    margin: 0;
  }

  svg {
    justify-self: center;
  }
}
