
@import '../../scss/config/config';
@import 'sass-mq/mq';

.app-header-basket {
  display: flex;
  position: relative;

  &::after {
    @include mq($from: desktop) {
      background-color: $white;
      content: '';
      display: block;
      height: 40px;
      margin-inline: 30px 20px;
      opacity: .6;
      width: 1px;
    }
  }
}

.svg-basket.empty-basket-icon {
  @include mq($until: mobile) {
    height: 28px;
    width: 28px;
  }
}

.basket-count {
  align-items: center;
  background-color: $yellow;
  border-radius: 50%;
  bottom: 4px;
  color: $black;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  height: 24px;
  justify-content: center;
  left: 16px;
  position: absolute;
  width: 24px;

  @include mq($from: mobile) {
    bottom: 18px;
    left: 26px;
  }
}
