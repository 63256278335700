.anchor-nav {
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: fixed;
  right: 20px;
  top: 30%;

  @include mq($until: tablet) {
    display: none;
  }
}

.anchor-nav-link {
  aspect-ratio: 1;
  border: 1px solid currentColor;
  border-radius: 50%;
  color: $white;
  display: block;
  width: 10px;

  &.active {
    background-color: $white;
  }

  &:hover {
    background-color: $white;
    color: $white;
  }
}
