
@import '../../scss/config/config';

.form-group {
  position: relative;
}

.dropdown-menu {
  background-color: $white;
  max-height: 440px;
  overflow-y: auto;
  padding: 10px;
  position: absolute;
  top: 38px;
  width: 100%;
  z-index: 10;
}

.dropdown-item {
  background-color: transparent;
  border-width: 0;
  cursor: pointer;
  display: block;
  padding: 10px;
  text-align: left;
  white-space: unset;
  width: 100%;

  &:not(:first-child) {
    border-color: $gray-300;
    border-width: 1px 0 0;
    box-shadow: inset 0 1px 2px rgb(0, 0, 0, .08);
  }
}
