
@import '../../scss/config/config';
@import 'sass-mq/mq';

.affiliate-navigation-links {
  display: flex;
  flex-direction: column;
  gap: 16px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.affiliate-navigation-link {
  align-items: center;
  border-radius: 8px;
  color: $white;
  display: grid;
  gap: 16px;
  grid-template-columns: 26px minmax(0, 1fr);
  padding: 16px 12px;
  position: relative;
  text-decoration: none;

  &.router-link-active {
    background-color: #ffffff4c;
  }

  svg {
    justify-self: center;
  }
}

.rib-empty {
  background-color: $pink-700;
  border-radius: 50%;
  display: block;
  height: 12px;
  position: absolute;
  right: 12px;
  width: 12px;
}
