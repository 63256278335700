
@import '../../scss/config/config';
@import 'sass-mq/mq';

.address {
  display: flex;
  font-family: $headings-font-family;

  .card-body {
    display: flex;
    flex-direction: column;
    line-height: $headings-line-height;
    padding: $spacer * 1.5;
  }
}

.address-title {
  display: block;
  font-size: 16px;
  font-weight: 700;
  gap: 5px;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.address-avatar {
  align-items: center;
  border-radius: 35px;
  box-shadow: 0 0 15px rgba(0, 0, 0, .15);
  display: flex;
  height: 35px;
  justify-content: center;
  overflow: hidden;
  width: 35px;
}

.form-check {
  margin-bottom: 10px;
}

.address-content {
  font-size: 14px;
  margin-bottom: 10px;
}

.address-card-status {
  font-size: 14px;
}

.action-icons-space {
  margin-right: 5px;
}

.address-card-actions {
  color: $primary;
  font-weight: 400;
  margin-top: auto;
  width: 100%;
}

.address-card-action {
  text-decoration: underline;
}
