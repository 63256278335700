
.styled-options-hidden {
  display: none;
}

.styled-select {
  align-items: center;
  color: #1a225f;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  padding-left: 10px;
  position: relative;
  user-select: none;
  width: 100%;
}

.styled-options {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .075);
  left: 0;
  position: absolute;
  top: 60px;
  width: 100%;
  z-index: 99;
}

.styled-option {
  background-color: #fff;
  color: #1a225f;
  padding: 10px 5px;

  &:hover {
    background-color: #9e99d81a;
  }
}
