
@import '../../scss/config/config';
@import 'sass-mq/mq';

.dashboard-navbar {
  background-color: $white;
  border-radius: $spacer;
  padding: $spacer;

  @include mq($from: tablet) {
    background-color: transparent;
    padding: 0;
  }
}

.app-subtitle,
.add-job-link {
  color: $body-color;

  @include mq($from: desktop) {
    color: $white;
  }

  .plus-icon-white {
    display: none;

    @include mq($from: desktop) {
      display: block;
    }
  }

  .plus-icon-dark {
    display: block;

    @include mq($from: desktop) {
      display: none;
    }
  }
}

.dashboard-job-card {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15);

  .card-body {
    padding: $spacer;
  }
}

.dashboard-navbar-btn {
  font-size: 15px;
}
