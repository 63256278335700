
@import '../../scss/config/config';
@import 'sass-mq/mq';

.profile-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include mq($from: tablet) {
    align-items: center;
    flex-direction: row;
  }

  @media print {
    display: none;
  }

  .application-job {
    font-size: 13px;
    margin-bottom: 1rem;
    order: 2;

    @include mq($from: tablet) {
      font-size: 14px;
      margin-bottom: 0;
      order: 1;
    }
  }

  .application-job-title {
    white-space: nowrap;
  }

  .return-to-resumes {
    font-weight: 700;
    margin-bottom: 1rem;
    order: 1;

    @include mq($from: tablet) {
      margin-bottom: 0;
      order: 2;
      text-align: right;
    }

    .return-icon {
      margin: 0 10px;
    }
  }
}

.application-job-name {
  background-color: $light;
  border-radius: 10px;
  margin-left: 10px;
  padding: 8px 15px;
}
