
@import '../../scss/config/config';

.form-group {
  position: relative;
}

.dropdown-menu {
  background-color: $white;
  max-height: 134px;
  overflow-y: auto;
  width: 100%;
  z-index: 10;
}

.dropdown-item {
  background-color: transparent;
  border-width: 0;
  cursor: pointer;
  display: block;
  padding: 10px;
  text-align: left;
  white-space: normal;
  width: 100%;
}

.dropdown-item-name {
  display: inline-flex;
  padding: 0 3px;
}
