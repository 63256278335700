
@import '../../scss/config/config';
@import 'sass-mq/mq';

.affiliate-mobile-header {
  background-color: $gray-900;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.mobile-logo {
  width: 64px;
}

.btn-offcanvas {
  background-color: $white;
  border-radius: 50%;
  height: 48px;
  padding: 0;
  width: 48px;

  svg {
    height: 24px;
    width: 24px;
  }
}

.offcanvas {
  background-color: $gray-900;
}

.offcanvas-header {
  margin-bottom: 32px;
  padding: 20px;
}

.offcanvas-body {
  padding: 0 20px 20px;

  .btn {
    margin-top: 20px;
    width: 100%;
  }
}

.btn-close-offcanvas {
  background-color: transparent;
  border: 0;
  padding: 0;

  .icon {
    width: 30px;
  }
}
