.homepage-offer-wrapper {
  background-image: linear-gradient(to bottom, $primary 80%, transparent 80%);
  margin-top: -300px;
  padding-top: 200px;
  position: relative;
  z-index: 2;
}

.top-curve {
  position: absolute;
  top: -2px;
  z-index: 1;

  .curve-img {
    width: 100%;
  }
}

.bottom-curve {
  bottom: calc(20% - 2px);
  position: absolute;
  transform: rotate(180deg);
  width: 100%;
  z-index: 1;

  .curve-img {
    width: 100%;
  }
}

.homepage-offer {
  position: relative;
  z-index: 2;
}

.homepage-offer-title {
  color: $white;
  font-size: 28px;
  margin: 2rem auto;
  max-width: 940px;
  padding: 2rem .25rem;

  @include mq($from: desktop) {
    font-size: 38px;
    padding: 2rem 0;
  }
}

.homepage-offer-subtitle {
  font-size: 22px;

  @include mq($from: desktop) {
    font-size: 32px;
  }
}

.homepage-offer-card {
  color: $dark-blue;
  margin: 0 auto;
  max-width: 640px;
  padding: 2rem 1rem;
  position: relative;
  z-index: 3;

  @include mq($from: tablet) {
    padding: 3rem;
  }

  li {
    margin-top: 1rem;
  }

  .premium-item {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}

.homepage-offer-card-essential {
  background-color: #ffe5ff;
  margin-bottom: $grid-gutter-width;

  @include mq($from: desktop) {
    margin-bottom: 0;
    margin-top: 2rem;
  }
}

.homepage-offer-card-title {
  border-bottom: 1px solid rgba(82, 95, 127, .15);
  color: $pink;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 2rem;
  padding: 0 0 2rem;
  text-align: center;
}

.homepage-offer-card-image {
  display: none;

  @include mq($from: desktop) {
    display: block;
    position: absolute;
    right: -50px;
    top: -50px;
    transform: scale(.66);

    .homepage-offer-card-essential & {
      left: -50px;
      right: auto;
    }
  }

  @include mq($from: extra-wide) {
    transform: none;
  }
}

.homepage-offer-text {
  border-top: 1px solid rgba(82, 95, 127, .15);
  margin-top: 2rem;
  padding-top: 2rem;
  text-align: center;
}

.homepage-offer-text-1 {
  font-size: 22px;
  font-weight: 700;
}

.homepage-offer-text-2 {
  font-size: 18px;
}

.homepage-offer-cta {
  margin-top: 1rem;
}

.homepage-offer-text-3 {
  font-size: 14px;
  margin-top: 18px;
}

.anchor-hidden {
  position: relative;
  top: -50px;
  visibility: hidden;
}
