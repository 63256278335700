
@import '../../scss/config/config';
@import '~sass-mq/mq';

.modal {
  color: $body-color;
}

.modal-body {
  padding-top: 0;
}

.new-job-modal {
  .modal-content {
    padding: 24px;

    @include mq($from: tablet) {
      padding: 32px;
    }
  }

  .modal-header,
  .modal-body {
    padding: 0;
  }
}
