.affiliation-header {
  background-color: #f8f7ec;
  height: var(--header-height);
  padding-block: 30px;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 2;

  @include mq($from: tablet) {
    padding-block: 60px;
  }

  .content {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;
    margin-inline: 20px;
    position: relative;

    @include mq($from: tablet) {
      margin-inline: 40px;
    }

    @include mq($from: desktop) {
      justify-content: center;
    }

    @include mq($from: wide) {
      margin-inline: 90px;
    }
  }

  .logo {
    max-width: 100%;
    width: 162px;

    @include mq($from: tablet) {
      width: 250px;
    }
  }

  .btn {
    font-family: var(--body-font-family);
    max-height: 45px;
    padding: 12px 24px;

    @include mq($from: desktop) {
      position: absolute;
      right: 0;
    }
  }
}
