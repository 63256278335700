
@use 'sass:math';
@import '../../scss/config/config';

.checkbox-indicator {
  align-items: center;
  border: 1px solid $black;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  padding: 6px;
  width: 24px;
}
