
@use 'sass:math';
@import '../../scss/config/config';
@import 'sass-mq/mq';

.register-summary {
  color: $dark-blue;
  position: sticky;
  top: 0;
}

.register-summary-subscription {
  border-bottom: 1px solid $gray-900;
  padding: $spacer 0;
}

.register-summary-detail {
  border-bottom: 1px solid $gray-900;
  padding: $spacer 0;

  .address-name {
    color: $dark-blue;
    text-decoration: none;
  }

  .icon {
    margin-left: auto;
    margin-right: 10px;
  }
}

.register-summary-detail.total {
  border-color: transparent;
}

.register-summary-detail-total-price {
  color: $dark-blue;
  font-size: 16px;
  margin-left: auto;
  text-align: right;
}

.register-summary-detail-price {
  color: $dark-blue;
  margin-left: 10px;
}

.register-summary-actions {
  display: grid;
  margin-bottom: 40px;

  @include mq($from: desktop) {
    margin-top: 40px;
  }
}

.register-summary-product {
  color: $gray-900;
}

.subscription-bonus {
  color: $gray-600;
  font-style: italic;
}
