
@import '../../scss/config/config';
@import 'sass-mq/mq';

.add-address-job .form-check-group {
  grid-template-columns: repeat(2, minmax(0, 1fr));

  @include mq($from: mobile) {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }

  .form-check-label {
    flex-direction: column;
    gap: 16px;
    min-height: 140px;
  }
}

.custom-check input:checked + .form-check-label {
  border-width: 2px;
}
