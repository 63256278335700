
@use 'sass:math';
@import '../../scss/config/config';
@import 'sass-mq/mq';

.form {
  padding-right: 50px;
}

.add-address-form {
  .register-address-product {
    display: block;
  }
}

.add-new-address {
  position: sticky;
  top: 0;
}

.add-new-address-summary-subscription {
  border-bottom: 1px solid $gray-300;
  padding: $spacer 0 $spacer;

  @include mq($from: tablet) {
    padding: $spacer * 3 0 $spacer;
  }
}

.register-address-products {
  display: grid;
  grid-gap: math.div($grid-gutter-width, 2);
  grid-template-columns: 1fr;

  @include mq($from: tablet) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.add-new-address-summary-detail {
  border-bottom: 1px solid $gray-300;
  padding: $spacer 0;

  .address-name {
    text-decoration: none;
  }

  .icon {
    margin-left: auto;
    margin-right: 10px;
  }
}

.add-new-address-summary-detail-total-price {
  color: #000;
  font-size: 16px;
  margin-left: auto;
  text-align: right;
}

.add-new-address-summary-detail-price {
  color: $gray-600;
  margin-left: 10px;
}

.add-new-address-summary-actions {
  display: grid;
  margin-top: 40px;
}

.add-new-address-summary-product {
  color: $gray-600;
}

.subscription-bonus {
  color: $gray-600;
  font-style: italic;
}

.dropdown-item {
  background-color: transparent;
  border-width: 0;
  cursor: pointer;
  display: block;
  padding: 10px;
  text-align: left;
  width: 100%;

  &:not(:first-child) {
    border-color: $gray-300;
    border-width: 1px 0 0;
    box-shadow: inset 0 1px 2px rgb(0, 0, 0, .08);
  }
}
