
@import '../../scss/config/config';
@import 'sass-mq/mq';

.candidate-age::after {
  content: ' • ';
}

.candidate-information-card {
  background-color: $light;

  .btn {
    text-align: center;
    width: 100%;

    @include mq($from: desktop) {
      text-align: left;
      width: auto;
    }
  }

  .btn-icon {
    img {
      max-height: 16px;
    }
  }
}

.candidate-information-card-email {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include mq($from: desktop) {
    max-width: 140px;
  }
}

.candidate-avatar {
  align-items: center;
  border: 3px solid $white;
  border-radius: 63px;
  display: inline-flex;
  height: 63px;
  justify-content: center;
  overflow: hidden;
  width: 63px;
}

.candidate-avatar-img {
  border: 3px solid $white;
  height: 63px;
  object-fit: cover;
  width: 63px;
}

.application-favorite {
  background: none;
  border: 0;
  padding: 0;
  position: absolute;
  right: 15px;
  top: 15px;
}
