
@use 'sass:math';
@import '../../scss/config/config';
@import 'sass-mq/mq';

.register-information-detail {
  margin-top: 32px;

  @include mq($from: tablet) {
    margin-top: 62px;
  }
}

.form-group {
  .text-muted {
    display: block;
    text-align: right;
  }
}
