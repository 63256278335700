.mobile-alert {
  align-items: center;
  background-color: $body-color;
  border: 0;
  border-radius: 0;
  color: $white;
  display: flex;
  height: 90px;
  left: 0;
  margin: 0;
  padding: 20px 16px 20px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  @include mq($from: mobile) {
    display: none;
  }

  .btn-close {
    filter: invert(1);
    opacity: 1;
    padding: 16px;
    position: unset;
  }
}

.mobile-alert-content {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;

  .logo {
    background-color: $pink-700;
    border-radius: 8px;
    height: 50px;
    padding: 5px;
    width: 50px;
  }

  .title {
    font-size: 12px;
    margin: 0;

    @include mq($from: 382px) {
      font-size: 14px;
      margin-left: 8px;
    }
  }

  .btn {
    color: $body-color;
    font-size: 12px;
    font-weight: 400;
    line-height: 1em;
    padding: 6px 12px;
  }
}
