
.input-group .icon {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
}

.input-group input {
  border-bottom-right-radius: 15px !important;
  border-top-right-radius: 15px !important;
  width: 100%;
}

.svg-eye,
.svg-eye-hide {
  position: relative;
  top: 3px;
}
