.set-password-modal {
  .modal-title {
    margin-bottom: $spacer;
    text-align: center;
  }

  .form-actions {
    display: flex;
    gap: $grid-gutter-width;
    justify-content: center;
  }

  .form-group {
    margin-bottom: 0;
    position: relative;
  }

  .form-text {
    margin-bottom: 1rem;
  }

  .form-control {
    padding-right: 2rem;
  }

  .icon {
    bottom: 12px;
    cursor: pointer;
    position: absolute;
    right: 12px;
    z-index: 5;

    svg {
      height: 26px;
      width: 26px;
    }
  }

  .is-invalid .icon {
    bottom: 37px;
  }

  // Hide hints about the password
  .form-control ~ .form-text {
    display: none;
  }
}
