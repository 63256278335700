.view-register-success {
  @each $breakpoint, $container-max-width in $container-max-widths {
    @if $container-max-width > 900 {
      @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        @include register-background($container-max-width, $grid-gutter-width * 3);
      }
    }
  }

  .logo {
    color: $primary;
  }
}
