
@import '../../scss/config/config';

.order-sticker {
  background-color: $light;
  border-radius: 10px;
  margin: 30px 0;
  padding: 30px 35px;
}

.order-sticker-label {
  font-size: 22px;
  margin-bottom: $spacer * 2;
  text-align: center;
}

.order-sticker-actions {
  align-items: center;
  display: inline-grid;
  gap: $spacer;
  grid-template-columns: 80px 1fr;
  justify-content: center;
}

.order-sticker-qrcode {
  margin-bottom: 18px;
}

