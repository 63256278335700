
@import '../../scss/config/config';
@import '~sass-mq/mq';

.brand-media-description {
  font-size: 14px;
}

.address-video-delete {
  background: none;
  border: 0;
  position: absolute;
  right: 5px;
  top: 5px;
}

.form-label {
  color: $primary;
  font-size: 13px;
  font-weight: 700;
}

.dropzone {
  align-items: center;
  border: 2px dashed $primary;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  height: 100px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100px;
}

.dropzone-label {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.dropzone-delete {
  background: transparent;
  border: 0;
  border-radius: 100%;
  padding: 0;
  position: absolute;
  right: 5px;
  top: 5px;
}

.dropzone-list {
  display: grid;
  gap: $spacer;
  grid-template-columns: repeat(3, 1fr);
}

.dropzone-input {
  left: -9999px;
  position: absolute;
}

.video-input {
  background-color: #352bab0d;
  border: 2px dashed $primary;
  padding-right: 25%;
}

.video-link-icon {
  background-color: #352bab;
  color: #fff;
  display: none;
  padding: 5px 20px;
  right: 10px;
  top: 38px;

  @include mq($from: tablet) {
    display: block;
  }
}

.btn-small {
  height: 40pt;
}
