
@use 'sass:math';
@import '../../scss/config/config';

.register-address-product {
  display: flex;
  flex-direction: column;
  gap: $spacer;
  justify-content: space-between;

  .form-control {
    margin-top: auto;
  }
}

.register-address-product-name {
  color: #000;
  font-size: 18px;
  margin-bottom: 0;
}

.register-address-product-description {
  font-size: 14px;
  margin-bottom: 0;
  min-width: 140px;
}

.register-address-product-price {
  min-width: 140px;

  .form-control {
    width: 100%;
  }
}
