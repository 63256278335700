.hero {
  background-color: $primary;
  color: $white;
  padding-block: 12px 20px;
  scroll-margin-top: var(--header-height);
  text-align: center;

  .surround {
    img {
      transform: translate3d(-5%, -20%, 0);
      width: 110%;
    }
  }

  @include mq($from: tablet) {
    padding-block: 40px 50px;
  }

  @include mq($from: desktop) {
    padding-bottom: 100px;

    .surround {
      img {
        transform: translate3d(-12%, -20%, 0);
        width: 130%;
      }
    }
  }

  @include mq($from: wide-xl) {
    padding-top: 96px;
  }
}

.hero-title {
  font-size: clamp(38px, 13vw, 112px);
  font-weight: 700;
  line-height: 1em;

  @include mq($from: wide-xl) {
    font-weight: 900;
    line-height: .9em;
  }
}

.hero-subtitle {
  font-size: clamp(16px, 2vw, 40px);
  font-weight: 500;
  margin-block: 15px 0;

  @include mq($from: wide) {
    margin-top: 40px;
  }
}

.hero-anchors {
  display: flex;
  flex-wrap: wrap;
  gap: 16px 40px;
  justify-content: center;
  margin-block: 52px 45px;

  @include mq($from: desktop) {
    margin-block: 114px 60px;
  }

  @include mq($from: wide-xl) {
    // Increase top margin
    margin-top: 190px;
  }
}

.btn-anchors {
  align-items: center;
  background-color: $pink-500;
  border: 2px solid $white;
  border-radius: 35px;
  color: $white;
  display: inline-flex;
  font-size: 22px;
  font-weight: 700;
  justify-content: center;
  min-height: 50px;
  min-width: 200px;
  padding: 10px 20px;
  position: relative;
  text-decoration: none;
  transition: all .2s;

  @include mq($from: desktop) {
    font-size: 1.5cqw;
    min-height: 55px;
  }

  @include mq($from: wide-xl) {
    font-size: 1.2cqw;
    min-height: 60px;
  }

  &:hover {
    background-color: $primary;
    color: $white;
  }
}

.visibility-anchor {
  position: relative;

  img {
    left: 50%;
    position: absolute;
    top: 16px;
    transform: translate3d(-50%, -100%, 0);

    @include mq($until: desktop) {
      display: none;
    }
  }
}

.job-search-form {
  background-color: $white;
  border: 1px solid $gray-900;
  border-radius: 16px;
  color: $gray-900;
  padding: 15px;
  position: relative;
  scroll-margin-top: 100px;
  text-align: left;

  .title {
    font-size: clamp(16px, 2vw, 25px);
    margin-bottom: 10px;
  }
}

.job-search-form-content {
  display: flex;
  gap: 12px;
  min-height: 50px;

  @include mq($from: desktop) {
    min-height: 60px;
  }

  .form-control {
    border-color: $gray-900;
    border-radius: 8px;
    padding: 12px 15px;

    @include mq($from: desktop) {
      font-size: 22px;
    }
  }

  .btn {
    min-width: 85px;
    padding: 12px 15px;

    img {
      width: 25px;

      @include mq($from: desktop) {
        width: 29px;
      }
    }
  }
}

.job-search-form-results {
  background-color: $white;
  border-color: $gray-900;
  border-radius: 0 0 16px 16px;
  border-style: solid;
  border-width: 0 1px 1px;
  left: -1px;
  padding: 20px;
  position: absolute;
  top: calc(100% - 12px);
  width: calc(100% + 2px);
  z-index: 2;

  &:empty {
    display: none;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .dropdown-item {
    padding-inline: 0;
    white-space: normal;
  }
}

.error-dropdown {
  background-color: #f8d7da;
  border: 1px solid #d9534f;
  border-radius: 5px;
  color: #d9534f;
  display: none;
  font-size: 14px;
  margin-top: 5px;
  padding: 8px 12px;

  .error-message {
    font-weight: bold;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
