
@import '../../scss/config/config';

.form-upper {
  text-transform: uppercase;

  &::placeholder {
    text-transform: none;
  }
}

.mail-options {
  display: grid;
  gap: 5px;

  .form-check-label {
    line-height: 1em;
    margin-left: 6px;
    margin-top: 8px;
  }
}

.form-check {
  .btn-radio-inversed {
    border-color: $primary;

    &:checked {
      background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#7556ed'/></svg>"));
    }

    &:checked + .label-text {
      color: $primary;
    }
  }
}

