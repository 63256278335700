
@import '../../scss/config/config';
@import '~sass-mq/mq';

.brand-recruitment-description {
  font-size: 14px;

  .hint {
    color: $secondary;
  }
}

.form-label {
  color: $primary;
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 0;
}

.form-control {
  height: 48px;
}

.brand-recruitment-delete {
  font-size: 14px;
  font-weight: 700;
  padding: 0;
  position: absolute;
  right: 0;
  text-decoration: none;
  top: 0;
}

.select-recruitment {
  border: hidden;
  border-radius: 6pt;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .075);
}

.select-recruitment > option {
  background-color: #fff;
  color: #1a225f !important;

  &:hover {
    background-color: #9e99d81a;
  }
}

