.homepage-contact-wrapper {
  background-color: $dark-blue;
  color: $white;
  padding: $spacer * 6 .25rem;

  @include mq($from: tablet) {
    padding: $spacer * 6 0;
  }
}

.homepage-contact {
  display: grid;
  gap: 60px;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  height: auto;
  scroll-margin-top: 80px;

  @include mq($from: tablet) {
    gap: 40px;
    grid-template-columns: 5fr 7fr;
    grid-template-rows: 1fr;
    scroll-margin-top: 100px;
  }
}

.homepage-contact-content-title {
  font-size: 20px;
  font-weight: 700;

  @include mq($from: mobile) {
    font-size: 28px;
  }

  @include mq($from: tablet) {
    font-size: 38px;
  }
}

.homepage-contact-content-subtitle {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 60px;
  margin-top: 60px;

  @include mq($from: mobile) {
    font-size: 28px;
  }

  @include mq($from: tablet) {
    font-size: 38px;
  }

  @include mq($from: desktop) {
    margin-bottom: .5rem;
  }
}

.homepage-contact-list-item {
  display: grid;
  gap: 20px;
  grid-template-columns: 19px 1fr;
}

.homepage-contact-form {
  label {
    color: $white;
    font-size: 16px;
  }

  input,
  textarea {
    background: #253976;
    border-color: #253976;
    color: $white;

    &:focus,
    &:active {
      background: #253976;
      border-color: #253976;
      color: $white;
    }

    &::placeholder {
      color: #aea6ef;
    }
  }

  .form-group-required {
    label::after {
      content: '';
    }
  }

  .form-group {
    &:not(.form-group-required) {
      label::after {
        content: ' (facultatif)';
        font-size: 11px;
      }
    }
  }
}

.homepage-contact-form-title {
  font-size: 20px;
  margin-bottom: 30px;

  @include mq($from: mobile) {
    font-size: 28px;
  }

  @include mq($from: tablet) {
    font-size: 38px;
  }
}
