.cdtn-header {
  .navbar {
    background-color: $primary;
    min-height: var(--header-height);

    @include mq($from: tablet) {
      border-bottom: 2px solid $cdtn-gray-bg;
    }
  }

  .container-max {
    display: flex;
    justify-content: center;
    position: relative;

    @include mq($from: tablet) {
      justify-content: space-between;
    }
  }

  .logo {
    min-width: 163px;
    width: 163px;

    @include mq($from: tablet) {
      min-width: 230px;
      width: 230px;
    }
  }

  .offcanvas {
    background-color: $primary;
    box-shadow: none;
  }

  .offcanvas-header {
    padding: 32px 20px 50px;
  }

  .offcanvas-body {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 0 20px 40px;

    @include mq($from: tablet) {
      flex-direction: row;
      justify-content: end;
      padding: 0;
    }
  }

  .offcanvas-start {
    width: 350px;
  }

  .btn-close {
    filter: invert(1);
  }

  .btn {
    align-items: center;
    border-radius: 16px;
    display: flex;
    font-family: var(--body-font-family);
    font-weight: 500;
    gap: 16px;
    justify-content: center;
    min-height: 50px;
    padding: 10px 20px;
    width: 100%;

    @include mq($from: tablet) {
      min-width: 190px;
      width: fit-content;
    }
  }

  .btn-icon {
    img {
      transition: .2s ease-in all;
      width: 20px;
    }

    &:hover img {
      filter: invert(1);
    }
  }
}

.navbar-toggler {
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
  left: 0;
  padding: 10px 20px;
  position: absolute;
  top: -6px;

  &:focus {
    box-shadow: none;
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px;
  }

  span {
    background-color: $white;
    display: block;
    height: 2px;
    width: 23px;

    &:nth-child(2) {
      width: 20px;
    }
  }
}
