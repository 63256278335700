.homepage-hero-wrapper {
  min-height: 100vh;
  overflow: hidden;
}

.homepage-hero {
  margin-top: calc(110px + 3rem);
  min-height: calc(100vh - 110px);
  position: relative;
  z-index: 1;
}

.homepage-hero-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 1000px;
  min-height: calc(100vh - 110px - 3rem);
}

.homepage-hero-video {
  bottom: 0;
  filter: brightness(50%);
  left: 0;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;

  img,
  video {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.homepage-hero-content-title {
  color: $white;
  font-family: $font-family-sans-serif;
  font-size: 45px;
  font-weight: 700;
  line-height: $headings-line-height;
  margin-top: auto;
  text-align: center;

  @include mq($from: desktop) {
    font-size: 120px;
    line-height: 120px;
  }
}

.homepage-hero-content-subtitle {
  color: $white;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 2rem;
  margin-top: 2rem;
  text-align: center;
}

.homepage-hero-scrolldown {
  margin-top: auto;
}
