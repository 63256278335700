
@import '../../scss/config/config';
@import '~sass-mq/mq';

.brand-values-description {
  font-size: 14px;
}

.form-label {
  color: $primary;
  font-size: 18px;
  font-weight: 700;
}

.hint {
  font-size: 12px;
  margin-top: .5rem;
  text-align: right;
}

.values-text {
  border-color: $pale-lavender;
  border-radius: 6pt;
}

