.logo {
  @include logo();
}

.logo {
  align-self: center;
  justify-self: center;

  @include mq($from: tablet) {
    align-self: unset;
    justify-self: unset;
  }

  &.premium a {
    display: block;
    font-size: 1rem;
  }
}
