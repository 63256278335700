
@import '../../scss/config/config';
@import 'sass-mq/mq';

.address-complete-form {
  .svg-magnifier {
    bottom: 11px;
    margin: auto 0;
    position: absolute;
    right: 20px;
    top: auto;
  }
}

.address-complete-job {
  border: 1px solid $input-border-color;
  border-radius: 15px;

  .form-control {
    border: 0;
    box-shadow: none;
    padding: 15px 12px;
  }

  .svg-enter {
    bottom: auto;
    margin: auto 0;
    position: absolute;
    right: 20px;
    top: 50px;
  }
}

.selected-jobs {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: 5px 10px;
}

.selected-job {
  border-radius: 15px;
  display: inline-flex;
  font-weight: 400;
  gap: 5px;
}

.selected-job-text {
  max-width: 380px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selected-job-remove {
  background: none;
  border: 0;
}

.form-control-logo {
  padding: 10px 12px;
}
