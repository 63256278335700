
@use 'sass:math';
@import '../../scss/config/config';
@import 'sass-mq/mq';

.resumes-actions-container {
  background-color: $white;
  border-radius: 56px;
  display: flex;
  margin-top: 32px;
  min-height: 56px;
  padding: 8px;
  width: 100%;

  @include mq($from: mobile) {
    width: fit-content;
  }

  .btn {
    border-radius: 40px;
    flex-grow: 1;
    font-weight: 500;
    padding: 0 12px;

    &:not(.btn-primary) {
      color: $primary;
    }
  }
}
