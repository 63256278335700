
@import '../../scss/config/config';

.notification {
  color: $white;
  font-family: $headings-line-height;
}

.notification-link {
  color: currentColor;
  text-decoration: none;
}

.user-name {
  margin-right: 10px;
}

.user-avatar {
  background-color: $light;
  border-radius: 50%;
  font-size: 12px;
  height: 32px;
  line-height: 12px;
  width: 32px;

  img {
    border-radius: 50%;
    height: 32px;
    width: 32px;
  }
}
