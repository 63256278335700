$mq-breakpoints: (
  mobile: 576px,
  tablet: 768px,
  desktop: 992px,
  wide: 1200px,
  wide-xl: 1420px,
  extra-wide: 1600px,
);

$primary: #7556ed;
$secondary: #ff0e8d;
$dark-secondary: #e8007c;
$dark-blue: #001659;
$light-blue: #f6f9fc;
$medium-blue: #ebeaf7;
$steel: #717296;
$pale-lavender: #ece9f1;
$cdtn-gray-bg: #fafafa;
$special-font: 'Barlow Condensed';

// Used for gradient background on register pages
$bg-color-change: calc(( 100vw - 1140px ) / 2 + ( 1140px * .73 ));

:root {
  --bs-gutter-x: 20px;
}
