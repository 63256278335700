
@import '../../scss/config/config';
@import 'sass-mq/mq';

.card-body,
.application-information {
  color: $gray-900;
  margin-top: 0;
  position: relative;

  @include mq($from: desktop) {
    margin-top: 40px;
  }
}

.application-job {
  margin-top: 2rem;

  ul {
    padding-left: 15px;
  }
}

.download-link {
  text-decoration: none;
  word-break: break-all;
}

.divider {
  background-color: $gray-400;
}

.last-jobs {
  background-color: $white;
  border: 0;
  margin-bottom: 20px;

  @include mq($from: desktop) {
    border: 1px solid $gray-400;
    border-radius: 10px;
    padding: 20px;
  }

  ul {
    margin-bottom: 0;
  }
}
